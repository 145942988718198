<template>
  <v-container fluid>
    <v-card flat class="d-print-none">
      <v-toolbar flat>
        <v-btn outlined class="mb-4" :disabled="readOnly" text @click="createNewMeal">New</v-btn>
        <v-btn outlined text class="ml-1 mb-4 mr-2" :disabled="readOnly || !meal" @click="copyMeal(meal)">
          Copy
          <v-icon v-if="meal && meal.is_selected" color="yellow">mdi-star</v-icon>
          <v-icon v-if="meal && meal.is_draft" color="blue">mdi-star</v-icon>
        </v-btn>
        <MealAutocomplete
          label="Search for existing meal (name or ID)"
          :meal.sync="meal"
          v-bind:key="meal && meal.id"
          hide-non-sku-starred-meals
        />
      </v-toolbar>

      <v-alert type="error" outlined v-if="meal && !meal.SKU">
        This meal does not have a SKU. Click to generate a SKU:
        <v-btn outlined @click="generateSku(meal)">Make me a SKU</v-btn>
        <br/>
        Note: Select a diet first if applicable.
      </v-alert>
      <v-alert type="default" outlined v-if="meal && meal.is_draft">
        This a draft version of SKU {{ meal.sku }}. The current SKU version is <a
        @click="loadMeal(referenceSkuMealId)">M{{ referenceSkuMealId }}</a>.
        <br/>
      </v-alert>
      <v-alert type="warning" outlined v-if="meal && !meal.is_selected && referenceSkuMealId && !meal.is_draft">This is
        not the
        <v-icon color="yellow">mdi-star</v-icon>
        reference version of the meal. Click here to open <a
          @click="loadMeal(referenceSkuMealId)">M{{ referenceSkuMealId }}</a>.
        <br/>
        Click <a @click="setDraftSku(meal)">here</a> to mark this version as the draft reference.
      </v-alert>
      <v-alert type="info" prominent text outlined v-if="!readOnly && !canEditMeal && !isAdmin">
        This meal is too close to production, so you cannot edit it.
        <br/>
        Meals after {{ cutoffEditDateFormatted }} can still be changed.
        <v-spacer/>
        <v-btn :to="{name: 'MealDesignAdmin', params: { id: meal.id}}">EDIT ANYWAY</v-btn>
      </v-alert>
      <v-alert type="warning" prominent text outlined dark v-if="!readOnly && isDateSet && isAdmin">
        This meal has a date assigned. As you have access, you can still edit it, but take care not to edit
        meals
        in the past or which are currently being prepared.
      </v-alert>
      <v-card-title v-if="meal && !hideNameAndAnalysis">
        {{ meal.name }}
        <v-spacer/>
        <template v-if="meal">
        <v-btn outlined text
               v-if="meal"
               :to="{ name: 'MealNutrientsMulti', params: { selection : `${meal.id}`}}">
          Nutrient Analysis
        </v-btn>
        <v-btn outlined class="ml-2" :to="{name: 'MealPrepWork', params: { id : meal.id}}">Change Assignments</v-btn>
        </template>
      </v-card-title>
    </v-card>
    <v-card flat class="ma-4" v-if="canEditMeal && !meal">
      <v-card-title>Click "New" to start creating a new meal</v-card-title>
    </v-card>
    <v-card class="ma-4" v-if="canEditMeal && meal && !hideAddComponent">
      <v-card-title class="pb-0">
        Add component
      </v-card-title>
      <v-card-text class="mt-0 pt-0">
        <span class="caption">
        This allows you to include a meal component into this recipe.  You can choose between the base component or a modified one from a meal (aka the meal version).
        The ingredient amounts will be adjusted so that the total weight of the component added to the meal is the value in
          "Amount" <span v-if="adjustedAmount">({{ adjustedAmount }}g)</span>.
        </span>
      </v-card-text>
      <v-card-text>
        <v-toolbar v-if="canEditMeal" flat>
          <ComponentAutocomplete
            label="Component"
            :component.sync="componentToAdd"
          />
          <v-spacer/>
          <v-select
            class="pl-2"
            clearable
            dense
            label="Meal Version"
            :items="componentMeals"
            item-value="id"
            item-text="name"
            v-model="selectedComponentMeal"
            :hint="selectedMealHint"
            return-object
          >
            <template slot="item" slot-scope="data">
              {{ data.item.date }} - {{ data.item.name }}({{ data.item.id }})
            </template>

          </v-select>
          <v-text-field
            class="table-input pl-2"
            label="Amount"
            dense
            type="number"
            v-model="adjustedAmount"
            suffix="g"
          />

          <v-btn outlined text class="ml-2 mb-4" @click="addComponent">Add to Meal</v-btn>
        </v-toolbar>


        <v-alert dense type="warning" v-if="componentToAdd && selectedComponentIngredients.length === 0">
          no ingredients, perhaps try a meal version
        </v-alert>

        <MealDetailTable
          v-if="componentToAdd && selectedComponentIngredients.length > 0"
          :component="componentToAdd"
          :ingredients="selectedComponentIngredients"
          :read-only="true"
        />

      </v-card-text>
    </v-card>
    <v-card flat v-if="meal">
      <v-card-text>
        <v-toolbar dense class="pb-12 pt-5" v-if="meal">
          <v-select
            label="Diet"
            :items="diets ? Object.values(diets) : []"
            v-model="meal.diet"
            item-value="id"
            item-text="name"
            @change="saveMeal"
            :disabled="!canEditMeal"
            clearable
          />
          <v-spacer/>
          <v-select
            label="Time of day"
            :items="timeOfDayOptions"
            v-model="meal.tod"
            item-value="id"
            item-text="name"
            @change="saveMeal"
            :disabled="!canEditMeal"
            clearable
          />
          <v-spacer/>
          <span v-if="!canEditMeal" class="mb-4 ">
            {{ dateFormatted }}
          </span>
          <v-text-field
            v-if="canEditMeal"
            label="Date"
            :value="dateFormatted"
            @keydown="showDatePicker=true"
            @click="showDatePicker=true"
            append-icon="mdi-calendar"
            clearable
            v-on:click:clear="clearDate"
          />
          <v-dialog
            v-model="showDatePicker"
            ref="dialog"
            width="290px"
          >
            <v-date-picker
              v-model="meal.date"
              @change="saveMeal(); showDatePicker=false"
            />
          </v-dialog>
        </v-toolbar>
        <MealDetail
          v-if="meal"
          :meal-id="`${meal.id}`"
          :read-only="!canEditMeal"
          :allow-name-edit="true"
          v-bind:key="mealVersion"
          :hide-create-component="hideAddComponent"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import MealAutocomplete from '@/components/MealAutocomplete';
import MealDetail from '@/components/MealDetail';
import MealDetailTable from '@/components/MealDetailTable';
import api from '@/api';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import ComponentAutocomplete from '@/components/ComponentAutocomplete';
import {
  convertIngredient,
  deepCopy,
  formatWeight,
  dateFormatted,
  normaliseIngredient,
  getNextOrderingDate
} from '@/store/utils';
import moment from "moment";


export default {
  name: "MealDesign",
  components: {MealDetailTable, ComponentAutocomplete, MealDetail, MealAutocomplete},
  data() {
    return {
      meal: null,
      searchMeal: null,
      showCopyDialog: null,
      showDatePicker: null,
      search: null,
      componentToAdd: null,
      counter: 0,
      selectedComponentMeal: null,
      componentMealIds: null,
      componentMeals: [],
      selectedAmount: null,
      adjustedAmount: null,
      timeOfDayOptions:
        'breakfast,lunch,dinner,extras'.split(',').map((v, i) => ({
          id: i + 1,
          name: v
        })),
      lookupById: '',
      dateInput: '',
      cutoffEditDate: null
    }
  },
  watch: {
    async componentToAdd(c) {
      if (c && c.meal_foods) {
        this.componentMealIds = [...new Set(c.meal_foods.map(m => m.mealid))];
        console.log('meal ids', this.componentMealIds);
        this.componentMeals = await Promise.all(this.componentMealIds.map(mealId => this.fetchMeal(mealId)));
        this.componentMeals = this.componentMeals.sort((b, a) => a.id - b.id);
        console.log('watch this.componentMeals', this.componentMeals);
      }
      this.selectedComponentMeal = null;
    },
    selectedComponentIngredients(i) {
      console.log('watch selectedComponentIngredients', i);
      this.selectedAmount = formatWeight(i.reduce((a, i) => i.cookedAmount + a, 0));
      this.adjustedAmount = this.selectedAmount;
    },
    '$route.params.id'(id) {
      console.log('$route.params.id', id);
      if (this.$route.name === 'MealDesign' || this.$route.name === 'MealDesigner') {
        this.setMealId(id);
      }
    },
    'meal.id'(val) {
      console.log('watch meal.id', [this.$route.params.id, val]);
      if (this.$route.params.id != val) {
        this.$router.push({params: {id: val}});
      }
    },
  },
  async mounted() {
    const nextOrderingDate = getNextOrderingDate();
    console.log('next ordering date', nextOrderingDate);
    this.cutoffEditDate = moment(nextOrderingDate).add(1, 'week');

    await Promise.all([
      this.setMealId(this.$route.params.id),
      this.fetchDiets()
    ]);
  },
  computed: {
    ...mapState(['diets']),
    ...mapGetters(['getMeal']),
    cutoffEditDateFormatted() {
      return dateFormatted(this.cutoffEditDate);
    },
    dateFormatted() {
      return dateFormatted(this.meal.date, {formatString: 'dddd MMM DD, YYYY'});
    },
    mealVersion() {
      return `${this.counter}-${this.meal && this.meal.id}`;
    },
    isDateSet() {
      return this.meal && !!this.meal.date;
    },
    isDate2WeeksAway() {
      return this.meal && this.meal.date && moment(this.cutoffEditDate).isAfter(this.meal.date);
    },
    canEditMeal() {
      return this.isAdmin ||
        (!this.readOnly && !(this.isDateSet && this.isDate2WeeksAway));
    },
    readOnly() {
      return !!this.$route.meta.readOnly;
    },
    selectedComponentIngredients() {
      if (!this.componentToAdd) {
        return [];
      }
      const foods = this.selectedComponentMeal
        ? this.componentToAdd.meal_foods.filter(m => m.mealid === this.selectedComponentMeal.id)
        : this.componentToAdd.component_foods;

      return foods
        .map(normaliseIngredient(this.meal))
        .map(i => ({
          ...i,
          component: this.componentToAdd
        })).map(convertIngredient);
    },
    selectedComponentMeals() {
      if (!this.componentToAdd) {
        return [];
      }
      const meals = this.componentToAdd
        .meal_foods
        .map(m => this.getMeal(m.mealid))
      console.log('selectedComponentMeals', meals);
      return [{}, meals];
    },
    selectedMealHint() {
      let meal = this.selectedComponentMeal;
      if (meal) {
        return [meal.sku, meal.id, meal.date].join(' | ');
      } else
        return ''
    },
    referenceSkuMealId() {
      return this.meal && this.meal.SKU && this.meal.SKU.selected_meal_id;
    }
  },
  methods: {
    ...mapActions([
      'fetchDiets',
      'fetchMeal',
      'replaceIngredientFood',
      'fetchMealIngredients'
    ]),
    ...mapMutations(['SET_MEAL']),
    convertIngredient,
    normaliseIngredient,
    formatWeight,
    async setMealId(id) {
      if (this.meal && this.meal.id == id) {
        //already set
        return;
      }
      if (id) {
        this.meal = await this.fetchMeal(id);
      } else {
        this.meal = undefined;
      }
      this.showCopyDialog = false;
      this.searchMeal = undefined;
    },
    createNewMeal() {
      api.post('v2/meal', {meal: {name: ''}})
        .then(r => {
          console.log('result', r);
          this.meal = r.data;
          this.SET_MEAL({id: this.meal.id, meal: this.meal});
          this.$router.push({params: {id: this.meal.id}});
        })
        .catch(e => console.error('error', e));
    },
    saveMeal() {
      console.log('saving', JSON.stringify(this.meal));
      api.put(`v2/meal/${this.meal.id}`, {meal: this.meal})
        .then(r => {
          console.log('result', JSON.stringify(r.data));
          this.meal = r.data;
          this.SET_MEAL({id: this.meal.id, meal: this.meal});
        })
        .catch(e => console.error('error', e));
    },
    async copyMeal(mealToCopy) {
      if (!mealToCopy.is_selected) {
        alert('this meal is not the reference version.  you should not copy it');
      }
      const mealId = mealToCopy.id;
      await api.post(`/v2/meal/copy/${mealId}`)
        .then(async r => {
          console.log('result', r);
          const newMeal = r.data;
          this.searchMeal = null;
          this.showCopyDialog = false;
          await this.$router.push({params: {id: newMeal.id}});
        })
        .catch(e => console.error('error', e));
    },
    async addComponent() {
      console.log('adding', deepCopy(this.componentToAdd));
      console.log('adding ingredients', this.selectedComponentIngredients);
      const componentIngredients = this.selectedComponentIngredients
        .map(i => ({mealid: this.meal.id, ...i}));
      // .map(normaliseIngredient(this.meal));
      console.log('adding ingredients', componentIngredients);

      if (this.selectedAmount !== this.adjustedAmount) {
        const ratio = this.adjustedAmount / this.selectedAmount;
        componentIngredients.forEach(i => i.portion_multiplier = i.portion_multiplier * ratio);
      }

      await Promise.all(
        componentIngredients
          .map(i => {
              return this.replaceIngredientFood({newIngredient: i});
              // console.log(`POST - v2/meal/${this.meal.id}/ingredients ${i.component_id} - ${i.foodid} `, i);
              // return api.post(`v2/meal/${this.meal.id}/ingredients`, i)
              //     .catch(e => {
              //       console.error('failed to add ingredient: ' + e, i);
              //     })

            }
          ));

      this.componentToAdd = null;
      console.log('this.meal before fetch', JSON.parse(JSON.stringify(this.meal)));
      await this.fetchMealIngredients({id: this.meal.id, force: true});
      console.log('this.meal after fetch', JSON.parse(JSON.stringify(this.meal)));
      this.counter++;
    },
    clearDate() {
      // console.log('clearing date');
      this.meal.date = null;
      this.saveMeal();
    },
    async loadMeal(id) {
      this.meal = await this.fetchMeal({id, force: true});
    },
    generateSku(meal) {
      const {id, diet} = meal;
      console.log('generating sku for meal', {diet, meal});
      return api.post('v2/meal/sku/new', {diet_id: diet || 0})
        .then(({data}) => {
          const newSku = data;
          return api.put(`v2/meal/${id}`,
            {
              meal: {
                sku: newSku.id
              }
            })
            .then(() => this.loadMeal(id));
        })
    },
    setDraftSku(meal) {
      const {id, sku} = meal;
      console.log('setting draft sku', {id, sku});
      return api.put(`v2/meal/sku/default/${this.meal.sku}`, {draft_meal_id: id})
        .then(({data}) => {
          console.log('set default', data);
          return this.loadMeal(id);
        })
    }
  },
  props: {
    isAdmin: {type: Boolean, default: false, required: false},
    hideAddComponent: {type: Boolean, default: false, required: false},
    hideNameAndAnalysis: {type: Boolean, default: false, required: false},
  }
}
</script>

<style scoped>
.table-input {
  height: 45px;
  width: 60px;
  font-size: 14px;
}

</style>
