<template>
  <!--  hide-no-data-->
  <!--  hide-selected-->
  <!--  return-object-->
  <!--  autofocus-->


  <!--      v-click-outside="isOpen && close()"-->
  <!--  NOTE:  no-filter because that is done in database -->
  <v-autocomplete
      clearable
      :label="label"
      no-filter
      @focus="isOpen=true"
      @blur="isOpen && close()"
      @change="isOpen && close()"
      v-model="editValue"
      :items="entries"
      :search-input.sync="search"
      color="black"
      dont-auto-select-first
      item-text="name"
      item-value="id"
      placeholder="Search for a component"
      :suffix="`${editValue?editValue.id:''}`"
      :hint="editValue?`${editValue.name}, ${editValue.id}, ${getDateString(editValue.date)}`:''"
      return-object
      hide-no-data
  >
    <template v-slot:append-item>
      <div v-intersect="searchMore"/>
    </template>
    <template slot="item" slot-scope="data">
      {{ getDateString(data.item.updated_at || data.item.inserted_at) }} - {{ data.item.name }} {{ (data.item.id) }}
      <v-icon color="blue" v-if="data.item.verified">mdi-shield-check</v-icon>
    </template>
  </v-autocomplete>
</template>
<script>
import api from '@/api';
import {deepCopy, getDateString} from '@/store/utils';

export default {
  props: {
    component: Object,
    label: String,
    mustHaveComponentFoods: {type: Boolean, default: null, required: false}
  },
  created() {
    this.editValue = deepCopy(this.component);
  },
  mounted() {
    this.editValue = deepCopy(this.component);
    this.search = this.editValue ? this.editValue.name : '';
    // console.log('componentAutocomplete mounted', this.editValue);
    this.entries = this.editValue ? [this.editValue] : [];
  },
  data() {
    return {
      editValue: null,
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      count: 0,
      from: 0,
      isOpen: null
    };
  },
  watch: {
    component() { //v) {
      // console.log('watch c', v);
      this.editValue = deepCopy(this.component);
      this.entries = this.editValue ? [this.editValue] : [];
    },
    search(query) {
      this.performSearch(query);
    }
  },
  methods: {
    getDateString,
    close() {
      // console.log('closing', this.editValue);
      this.$emit('close', this.editValue);
      this.$emit('update:component', this.editValue);
    },
    searchMore() {
      const nextFrom = this.entries.length;
      if (nextFrom < this.count) {
        this.performSearch(this.query, nextFrom);
      } else {
        // console.log('already at the end', {count: this.count, loaded: this.entries.length});
      }
    },
    performSearch(query, from = 0) {
      query = query ? query.trim() : '';
      if (!query) {
        // console.log('no query')
        return;
      }
      // console.log('query', query);
      if (this.isLoading) {
        // console.log('Items have already been requested');
        return;
      }
      this.isLoading = true
      const where = {};
      const queryParts = query.split(' ').map(p => p.trim()).filter(p => !!p);
      if (queryParts.length === 1) {
        where.name = {
          contains: query,
          mode: 'insensitive',
        };
      } else {
        where.AND = queryParts.map(qp => ({
          name: {
            contains: qp,
            mode: 'insensitive',
          }
        }));
      }
      if (this.mustHaveComponentFoods) {
        where.component_foods = {
          some: {}
        };
      }
      api.get(`/v2/component`, {
        params: {
          from,
          // limit,
          sortBy: ['verified', 'name'],
          sortDesc: [false, false],
          // orderBy: [{verified: true}, {updated_at: 'desc'}],
          where,
          minimal: true
        }
      })
          .then(res => res.data)
          .then(result => {
            this.from = from;
            this.count = result.total
            if (from > 0) {
              // console.log('appending')
              result.rows.forEach(e => this.entries.push(e));
            } else {
              // console.log('replacing')
              this.entries = result.rows;
            }
            this.query = query;
            // console.log('api search result', result);
            // console.log('loaded entries', {entries: this.entries, from: this.from, count: this.count});

          })
          .catch(err => {
            console.warn('failure searching', err);
          })
          .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style scoped>

</style>
